import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { Time } from './Time';
import { Cycle } from './Cycle';
import { Version } from '../../../features/version/Version';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { usernameRemoved } from '../../../features/flight-plan/state/flight-plan-slice';
import { useLocalStorage } from '../../hooks/useLocalStorage';

export const DefaultFooter = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const username = useAppSelector((state) => state.flightPlan.simbriefUsername);
  const [_, setValue] = useLocalStorage('simbriefUsername', '');

  const handleUnloadFlightPlan = () => {
    setValue(undefined);
    dispatch(usernameRemoved());
    navigate('/simbrief');
  };

  return (
    <Grid
      container
      id="footer"
      sx={{ bgcolor: 'background.paper', height: 64 }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={2}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '0.5rem',
          }}
        >
          <Cycle />
        </Box>
      </Grid>
      <Grid item xs={2}>
        {username && (
          <Button
            color="error"
            variant="outlined"
            onClick={() => handleUnloadFlightPlan()}
          >
            Unload Simbrief
          </Button>
        )}
      </Grid>
      <Grid item xs={4}>
        <Version />
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Time localtime={true} name="Local" />
          <Time localtime={false} name="UTC" />
        </Box>
      </Grid>
    </Grid>
  );
};
